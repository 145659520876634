import React from "react";
import { Modal, Button } from "react-bootstrap";
import StripeElements from "../../components/trialDays/StripeElements";
import Loader from "../../components/loader/Loader";

const PaymentModals = ({
                           deleteModal,
                           setDeleteModal,
                           cancelDel,
                           delId,
                           handleDeleteCard,
                           cardAdded,
                           handleCardAdded,
                           loadingAddCard,
                           stripeData,
                           defaultCardModal,
                           setDefaultCardModal,
                           defaultCardNo,
                           defaultCardId,
                           confirmDefaultCard,
                           loadingDef,
                       }) => {
    return (
        <>
            <Modal
                className="deleteModal confirmModel"
                show={deleteModal}
                onHide={() => setDeleteModal(false)}
                centered
                size="md"
            >
                <Modal.Header closeButton>
                    <Modal.Title as="h4">Delete payment method?</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this payment method?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={cancelDel}>
                        Cancel
                    </Button>
                    {delId && (
                        <Button variant="primary" onClick={() => handleDeleteCard(delId)}>
                            Delete
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>

            <Modal
                className="addNewCardModak confirmModel "
                show={cardAdded}
                onHide={() => handleCardAdded(false)}
                centered
                size="md"
            >
                <Modal.Header closeButton>
                    <Modal.Title as="h4">Add credit or debit card</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loadingAddCard ? (
                        "Loading"
                    ) : (
                        <div>
                            <p className="modalP cardDet">Card details</p>
                            {stripeData && !loadingAddCard && <StripeElements stripeData={stripeData} />}
                        </div>
                    )}
                </Modal.Body>
            </Modal>

            <Modal
                className="defaultCardModal confirmModel"
                show={defaultCardModal}
                onHide={() => setDefaultCardModal(false)}
                centered
                size="md"
            >
                <Modal.Header closeButton>
                    <Modal.Title as="h4">Change default payment method?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Change default payment method to {defaultCardNo.brand || "AMEX"} ending in {defaultCardNo.last4 || "1234"}?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setDefaultCardModal(false)}>
                        Cancel
                    </Button>
                    {defaultCardId && (
                        <Button variant="primary" onClick={confirmDefaultCard}>
                            Change
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>

            {loadingDef && <Loader />}
        </>
    );
};

export default PaymentModals;