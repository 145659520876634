import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import LoginCountriesModal from "../components/modals/LoginCountriesModal";
import {ReactCountryFlag} from "react-country-flag";
import OTP from "../components/login/OTP";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { LocalStorage } from "../app/helpers";
import {globalConstants} from "../services/globalVariables";

const BASEURL = globalConstants.BASE_URL;

const Login = (props) => {
    const {addError, addLog, addSuccess, trial, dlcReg} = props;
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState({
        id: "1",
        country: "United States",
        destination: "+1",
        iso: "US",
    });
    const [phoneNumber, setPhoneNumber] = useState("");
    const [submissionSuccessful, setSubmissionSuccessful] = useState(false);
    const phoneRegExp =
        /^((\+[1-9]{1,4}[ -]*)|(\([0-9]{2,3}\)[ -]*)|([0-9]{2,4})[ -]*)*?[0-9]{3,4}?[ -]*[0-9]{3,4}?$/;
    const validationSchema = Yup.object({
        inputCodeCountry: Yup.string().required("Country code is required"),
        phoneInput: Yup.string()
            .matches(phoneRegExp, "Phone number is not valid")
            .min(5, "Phone number must be at least 5 digits")
            .max(12, "Phone number must be exactly 12 digits")
            .required("Phone number is required"),
    });


    const formik = useFormik({
        initialValues: {
            inputValue: "United States",
            inputCodeCountry: selectedCountry.destination,
            phoneNumber: "",
        },
        validationSchema: validationSchema,

        onSubmit: async (values) => {
            try {
                const formattedPhoneNumber = `${
                    selectedCountry.destination
                }${values.phoneInput.replace(/\s/g, "")}`;
                setPhoneNumber(formattedPhoneNumber);

                const requestBody = {
                    login: formattedPhoneNumber,
                };
                const response = await axios.post(
                    `${BASEURL}public/v1/user/login/otp`,
                    requestBody
                );
                formik.setStatus(response.status);
                if (response.status === 200) {
                    addLog("submitted");
                    setSubmissionSuccessful(true);
                }else {
                    addError("Response data:", response.data);
                }

            } catch (error) {
                console.log(error)
                addError("Error: " + (error?.response?.data?.message || error.message));
            }
        },
    });

    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const formatPhoneNumber = (value) => {
        if (value) {
            const numericValue = value.replace(/\D/g, "");
            const limitedValue = numericValue.slice(0, 10);
            const formattedValue = limitedValue.replace(
                /(\d{3})(\d{3})(\d{4})/,
                "$1 $2 $3"
            );
            return formattedValue;
        }
        return "";
    };

    const handleSelectCountry = (country) => {
        setSelectedCountry(country);
        handleCloseModal();
    };

    useEffect(() => {
        const userData = LocalStorage.getUser();
        const hasRefreshed = sessionStorage.getItem("hasRefreshed");

        if (userData?.customer ) {
            if(dlcReg !== true){
                setTimeout(() => {
                    navigate('/billing');
                })
            } else {
                if (!hasRefreshed) {
                    sessionStorage.setItem("hasRefreshed", "true");
                    window.location.reload();
                }
            }
        }
    }, []);

    return (
        <section className="login-wrapper">
            {submissionSuccessful ? (
                <OTP
                    setSubmissionSuccessful={setSubmissionSuccessful}
                    addSuccess={addSuccess}
                    addError={addError}
                    phoneNumber={phoneNumber}
                    phoneInput={formik.values.phoneInput.replace(/\s/g, "")}
                    connectionDetails={{companyId: "yourCompanyId", role: "admin"}}
                    dlcReg={dlcReg}
                />
            ) : (
                <form onSubmit={formik.handleSubmit}>
                    <div className="mb-5">
                        <h1 className="title">Log in</h1>
                        <button
                            className="login-button__country btn"
                            onClick={handleShowModal}
                        >
                            <ReactCountryFlag
                                countryCode={selectedCountry.iso}
                                svg
                                style={{
                                    width: "24px",
                                    height: "24px",
                                    marginRight: "16px",
                                    borderRadius: "20px",
                                    objectFit: "cover",
                                }}
                                className={selectedCountry.iso}
                            />
                            <span>{selectedCountry.country}</span>
                        </button>
                        <div className="login-button__wrapper-phone">
                            <input
                                type="text"
                                className="login-button__index"
                                placeholder={formik.values.inputCodeCountry}
                                onBlur={formik.handleBlur}
                                value={selectedCountry.destination}
                                name="inputCodeCountry"
                                readOnly
                            />
                            <div>
                                <input
                                    type="text"
                                    className={`login-button__phone ${
                                        formik.touched.phoneInput && formik.errors.phoneInput
                                            ? "input-error"
                                            : ""
                                    }`}
                                    placeholder="--- --- ----"
                                    value={formatPhoneNumber(formik.values.phoneInput)}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        const formattedValue = formatPhoneNumber(e.target.value);
                                        formik.setFieldValue("phoneInput", formattedValue);
                                    }}
                                    onBlur={formik.handleBlur}
                                    name="phoneInput"
                                />
                                {formik.touched.phoneInput && formik.errors.phoneInput && (
                                    <span className="input-error-text">
                    {formik.errors.phoneInput}
                  </span>
                                )}
                            </div>
                        </div>
                        <LoginCountriesModal
                            show={showModal}
                            onHide={() => handleCloseModal(false)}
                            onSelectCountry={handleSelectCountry}
                        />
                    </div>
                    <button
                        type="submit"
                        className="btn base-secondary d-flex container justify-content-center"
                    >
                        Send Code
                    </button>
                </form>
            )}
        </section>
    );
};

export default Login;
