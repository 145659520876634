import React, { useState, useEffect, useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import {
  postDefaultCard,
  deleteCard,
  postPayments,
  getPaymentsCard,
  postSubscription,
} from "../../services/billing";
import Loader from "../../components/loader/Loader";
import ProjectContext from "../../context/ProjectContext";

import MastercardImg from "../../assets/images/mastercard.png";

import StripeElements from "../../components/trialDays/StripeElements";
import {LocalStorage} from "../../app/helpers";
import PaymentModals from "./PaymentModals";

const Cards = () => {
  const { handleCardAdded, cardAdded } = useContext(ProjectContext);
  const [loading, setLoading] = useState(true);
  const [loadingDef, setLoadingDef] = useState(false);
  const [loadingAddCard, setLoadingAddCard] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [defaultCardModal, setDefaultCardModal] = useState(false);
  const [defaultCardId, setDefaultCardId] = useState("");
  const [defaultCardNo, setDefaultCardNo] = useState("");
  const [delId, setDelId] = useState();
  const [companyId, setCompanyId] = useState();
  const [cards, setCards] = useState([]);
  const [stripeData, setStripeData] = useState();
  const [error, setError] = useState(false);

  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState();
  const getPay = async (company) => {
    try {
      await getPaymentsCard(company).then((response) => {
        // console.log("response: ", response);
        setCards(response.data.cards);
        setDefaultPaymentMethod(response.data.defaultPaymentMethod);
        setLoading(false);
      });
    } catch (ex) {}
  };
  const handleDefaultPay = async (id) => {
    // setLoadingDef(true);
    // setDefaultPaymentMethod(id);
    // setDefaultCardModal(false);
    const bodyData = {
      setDefault: 1,
    };
    try {
      await postDefaultCard(companyId, id, bodyData).then((response) => {
        getPay(companyId);
        handlePostSubscription();
        // setLoadingDef(false);
        window.location.reload();
      });
    } catch (ex) {
      // setLoadingDef(false);
      getPay(companyId);
      window.location.reload();
    }
  };
  const handleDeleteCard = async (id) => {
    // setLoadingDef(true);
    // const newCards = cards.filter(i => )
    setCards((cards) => {
      return cards.filter((i) => i.id !== id);
    });
    setDeleteModal(false);
    try {
      await deleteCard(companyId, id).then((response) => {
        getPay(companyId);
        // setLoadingDef(false);
      });
    } catch (ex) {
      // setLoadingDef(false);
      getPay(companyId);
    }
  };

  const handleCheck = (id) => {
    if (id === defaultPaymentMethod) {
      return true;
    } else {
      return false;
    }
  };
  // pending comp

  const handleDeleteModal = (id) => {
    setDelId(id);
    setDeleteModal(true);
  };
  const cancelDel = () => {
    setDelId("");
    setDeleteModal(false);
  };

  const handleAddNew = async () => {
    const company = LocalStorage.getCompany();
    setLoadingAddCard(true);
    try {
      await postPayments(company).then((response) => {
        setStripeData(response.data);
        // console.log("response: ", response);
        setLoadingAddCard(false);
        handleCardAdded(true);
      });
    } catch (ex) {
      setLoadingAddCard(false);
      setError(true);
      console.log("ex:", ex);
    }
  };

  const handlePostSubscription = async () => {
    const company = LocalStorage.getCompany();
    try {
      await postSubscription(company).then((response) => {
        // console.log("response-pay-post", response);
        // window.location.reload();
      });
    } catch (ex) {
      console.log(ex);
    }
  };
  /*
  useEffect(() => {
    const company = LocalStorage.getCompany();
    setCompanyId(company);
    if (!cardAdded) {
      getPay(company);
    }
  }, [cardAdded]);

  */
  useEffect(() => {
    const company = LocalStorage.getCompany();
    // getPay(company);
    setCompanyId(company);
    getPay(company);
  }, []);

  if (loading) {
    // return <Loader />;
    //     return <div className="trailEnded bg-white p-4">
    //     <h6><span>Payment Method</span><button>Add New</button></h6>
    //     <ul className="paymentMethod">
    //        <li>
    //        <div className="labelradio">
    //              <span className="cardRadio">--</span>
    //              <div className="cardListExp">--</div>
    //              <span className="delCard">
    //               --
    //              </span>
    //           </div>
    //        </li>
    //        <li>
    //        <div className="labelradio">
    //              <span className="cardRadio">--</span>
    //              <div className="cardListExp">--</div>
    //              <span className="delCard">
    //               --
    //              </span>
    //           </div>
    //        </li>
    //        <li>
    //        <div className="labelradio">
    //              <span className="cardRadio">--</span>
    //              <div className="cardListExp">--</div>
    //              <span className="delCard">
    //               --
    //              </span>
    //           </div>
    //        </li>
    //        <li>
    //        <div className="labelradio">
    //              <span className="cardRadio">--</span>
    //              <div className="cardListExp">--</div>
    //              <span className="delCard">
    //               --
    //              </span>
    //           </div>
    //        </li>
    //        <li>
    //        <div className="labelradio">
    //              <span className="cardRadio">--</span>
    //              <div className="cardListExp">--</div>
    //              <span className="delCard">
    //               --
    //              </span>
    //           </div>
    //        </li>
    //        <li>
    //           <div className="labelradio">
    //              <span className="cardRadio">--</span>
    //              <div className="cardListExp">--</div>
    //              <span className="delCard">
    //               --
    //              </span>
    //           </div>
    //        </li>
    //     </ul>
    //  </div>;
  }

  const handleDefaultCardModal = (id, itm) => {
    setDefaultCardId(id);
    setDefaultCardModal(true);
    setDefaultCardNo(itm);
  };
  const confirmDefaultCard = (id) => {
    handleDefaultPay(defaultCardId);
    // setDefaultCardModal(false);
  };
  if (error) {
    return <div className="error">Error</div>;
  }
  return (
    <>
      <div className="trailEnded bg-white p-4">
        <h6>
          <span>Payment Method</span>
          <button onClick={handleAddNew}>Add New</button>
        </h6>
        {cards && cards.length > 0 && (
          <ul className="paymentMethod">
            {cards.map((itm) => (
              <li>
                <div className="labelradio">
                  <span className="cardRadio">
                    <input
                      className="radio-billing"
                      type="radio"
                      name="billingcycle"
                      value="annually"
                      // onChange={() => handleDefaultPay(itm.id)}
                      onChange={() => handleDefaultCardModal(itm.id, itm)}
                      checked={handleCheck(itm.id)}
                    />
                    <label className="inputRadio"></label>
                  </span>
                  <span className={`cardIcon-${itm.brand}`}>
                    <img
                      src={MastercardImg}
                      className="announcementimg"
                      alt="Qara"
                    />
                  </span>
                  <div className="cardListExp">
                    <span className="cardLast">xxxx{itm.last4}</span>
                    <span className="cardExp">
                      Expiry{" "}
                      {itm.expMonth < 10 ? "0" + itm.expMonth : itm.expMonth}/
                      {itm.expYear}
                    </span>
                  </div>
                  <span className="delCard">
                    {!handleCheck(itm.id) && (
                      <button onClick={() => handleDeleteModal(itm.id)}>
                        <svg
                          width="15"
                          height="17"
                          viewBox="0 0 15 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.3323 6.33036C13.4918 6.33036 13.6365 6.39996 13.7509 6.51756C13.8575 6.64316 13.9112 6.79916 13.8956 6.96396C13.8956 7.01836 13.4692 12.4103 13.2257 14.6799C13.0732 16.0727 12.1754 16.9183 10.8286 16.9415C9.79299 16.9647 8.78076 16.9727 7.78409 16.9727C6.72595 16.9727 5.69116 16.9647 4.6867 16.9415C3.38504 16.9103 2.4864 16.0495 2.34169 14.6799C2.09116 12.4023 1.67257 7.01836 1.66479 6.96396C1.65701 6.79916 1.70992 6.64316 1.81729 6.51756C1.9231 6.39996 2.0756 6.33036 2.23587 6.33036H13.3323ZM9.43284 0.972656C10.1401 0.972656 10.7718 1.46625 10.9547 2.17024L11.0854 2.75424C11.1912 3.23023 11.6036 3.56703 12.0782 3.56703H14.4107C14.722 3.56703 14.9811 3.82543 14.9811 4.15422V4.45822C14.9811 4.77902 14.722 5.04542 14.4107 5.04542H1.15214C0.840143 5.04542 0.581055 4.77902 0.581055 4.45822V4.15422C0.581055 3.82543 0.840143 3.56703 1.15214 3.56703H3.48471C3.95853 3.56703 4.3709 3.23023 4.47749 2.75504L4.59964 2.20944C4.78948 1.46625 5.41425 0.972656 6.12927 0.972656H9.43284Z"
                            fill="#8A8A8F"
                          />
                        </svg>
                      </button>
                    )}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
      <PaymentModals
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
          cancelDel={cancelDel}
          delId={delId}
          handleDeleteCard={handleDeleteCard}
          cardAdded={cardAdded}
          handleCardAdded={handleCardAdded}
          loadingAddCard={loadingAddCard}
          stripeData={stripeData}
          defaultCardModal={defaultCardModal}
          setDefaultCardModal={setDefaultCardModal}
          defaultCardNo={defaultCardNo}
          defaultCardId={defaultCardId}
          confirmDefaultCard={confirmDefaultCard}
          loadingDef={loadingDef}
      />
      {loadingDef && <Loader />}
    </>
  );
};

export default Cards;
